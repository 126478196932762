<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <div class="grid grid-cols-12">
      <div class="col-span-6">
        <h1 class="text-3xl mb-5">Suporte</h1>
        <div class="grid grid-cols-3 md:grid-cols-3 gap-3">
          <router-link
            :to="`/suporte/form`"
            type="button"
            :style="{ 'background-color': $store.state.colorPrincipal, color: invertColor($store.state.colorPrincipal, true) }"
            class="mb-2 bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            <span class="inline-block mr-2">Abra um ticket</span>
          </router-link>
        </div>
        <div class="grid grid-cols-12 gap-3 mb-3">
          <div class="col-span-12 md:col-span-6">
            <label for="status" class="block text-sm font-medium text-gray-700">Status</label>
            <v-select
              v-model="status"
              :options="[
                { nome: 'Em aberto', _id: 'Em aberto' },
                { nome: 'Concluído', _id: 'Concluído' },
                { nome: 'Pendente de informações', _id: 'Pendente de informações' },
                { nome: 'Em investigação', _id: 'Em investigação' }
              ]"
              label="nome"
              :reduce="(c) => c._id"
              name="status"
              id="status"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            ></v-select>
          </div>
          <div class="col-span-12 md:col-span-2">
            <button
              @click="start()"
              type="button"
              class="md:mt-7 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
            >
              <span class="inline-block mr-2">Buscar</span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="avisos.length > 0" class="col-span-6 border rounded shadow-xl py-3 px-1 overflow-y-auto h-52">
        <h1 class="text-base mb-1 pl-3">Avisos do sistema:</h1>
        <div>
          <div v-for="aviso in avisos" :key="aviso._id" class="p-3 mb-1 shadow-md" :class="categoriaAvisoValidate(aviso)">
            <h1 class="text-sm mb-1 flex justify-between">
              <span>{{ aviso.titulo }}</span>
              <span class="text-xs">{{ moment(aviso.dataInicio).format("DD/MM HH:mm") }}</span>
            </h1>
            <div class="">
              <span class="inline-block text-xs mr-2">{{ aviso.descricao }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Número</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ticket Abertos</th>
                  <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Prioridade</th>
                  <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Opções</th>
                </tr>
              </thead>

              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="ticket in list" :key="ticket._id">
                  <td class="px-2 w-20 text-center py-3">
                    <div class="text-center shadow-md rounded text-sm 2xl:text-base w-20 py-3 whitespace-nowrap bg-green-100">
                      <span class="inline-block">{{ ticket.numero }}</span>
                    </div>
                  </td>
                  <td class="text-sm 2xl:text-base whitespace-nowrap rounded-md">
                    <span class="inline-block h-full w-full py-1 px-2 rounded-md font-semibold text-left"> {{ ticket.titulo ? ticket.titulo : "" }}</span>
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    <div class="text-center shadow-md rounded text-sm 2xl:text-base px-2 py-3 whitespace-nowrap" :class="statusValidate(ticket)">
                      <span class="inline-block">{{ ticket.status }}</span>
                    </div>
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    <div class="text-center shadow-md rounded text-sm 2xl:text-base px-2 py-3 whitespace-nowrap" :class="prioridadeValidate(ticket)">
                      <span class="inline-block">{{ ticket.prioridade }}</span>
                    </div>
                  </td>
                  <td class="text-sm 2xl:text-base px-1 py-3">
                    <router-link
                      :to="`/suporte/form/${ticket._id}`"
                      type="button"
                      class="w-full rounded-md font-semibold text-center text-center shadow-md rounded text-sm py-3 whitespace-nowrap bg-gray-300 hover:bg-gray-400"
                    >
                      <span class="inline-block"> Detalhes </span>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumbs.vue";
import moment from "moment";

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      moment,
      breadcrumb: [{ url: "/suporte", value: "Suporte" }],
      list: [],
      status: "",
      avisos: []
    };
  },
  methods: {
    async start() {
      const tickets = await this.$http.post("/v1/ticket/list", { status: this.status });

      const avisos = await this.$http.get("/v1/notify");
      console.log(avisos.data.data);

      this.list = tickets.data.ticket;
      this.avisos = avisos.data.data;
    },

    statusValidate(ticket) {
      if (ticket.status === "Em aberto") return "bg-green-300";
      if (ticket.status === "Em investigação") return "bg-yellow-300";
      if (ticket.status === "Pendente de informações") return "bg-red-300";
      if (ticket.status === "Concluído") return "bg-blue-300";
      return "bg-gray-300";
    },

    prioridadeValidate(ticket) {
      if (ticket.prioridade === "Alta") return "bg-red-400";
      if (ticket.prioridade === "Média") return "bg-red-200";
      if (ticket.prioridade === "Baixa") return "bg-gray-200";
      return "bg-gray-300";
    },

    categoriaAvisoValidate(aviso) {
      if (aviso.nivel === "Alta") return "bg-yellow-300";
      if (aviso.nivel === "Média") return "bg-yellow-200";
      if (aviso.nivel === "Baixa") return "bg-yellow-100";
      return "bg-gray-300";
    },

    async remove(centro) {
      if (confirm("Tem certeza que deseja excluir esse centro de custo?")) {
        const resp = await this.$http.delete(`/v1/centrocusto/${centro}`);
        if (!resp.data.success) {
          this.$vToastify.error(resp.data.err);
          return;
        }
        this.$vToastify.success("Centro removido");
        this.start();
      }
    }
  },
  async beforeMount() {
    this.start();
  }
};
</script>
